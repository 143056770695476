<template>
  <div class="production-calendar-page column gap-s">
    <h1 class="subtitle">Производственный календарь</h1>

    <div class="between">
      <div class="production-calendar-page__select w-100 wrap">
        <VSelect
          v-model="selectedYear"
          label="Год:"
          item-text="value"
          :options="yearList"
          :allow-empty="false"
          :disabled="isLoading"
        />

        <SpinnerSimple v-if="isLoading" class="production-calendar-page__select-loader spinner-simple--main" />
      </div>

      <div class="flex gap-m">
        <VButton
          v-if="$store.getters['permission/isAdmin'] && isEdit"
          text="Сброс календаря"
          size="sm"
          icon="reset"
          @click.native="openModal"
        />

        <VButton
          v-if="$store.getters['permission/isAdmin'] && !isEdit"
          text="Редактировать"
          size="sm"
          icon="edit"
          @click.native="toggleEdit"
        />
      </div>
    </div>

    <ProductionCalendar
      ref="productionCalendar"
      :year="selectedYear.value"
      :is-disabled="!isEdit"
    />

    <ProductionCalendarFooter :year="selectedYear.value" />

    <ButtonGroup
      v-if="isEdit"
      text-btn-confirm="Сохранить"
      text-btn-reject="Отмена"
      btn-size="l"
      :loading="isLoading"
      @confirm="onSave"
      @reject="onCancel"
    />

    <ModalConfirmation
      v-if="isModal"
      :title="`Установить выходные производственного календаря за ${selectedYear.value} год по умолчанию (Сб, Вс)?`"
      :loading="$store.state.isSendingRequestModal"
      @confirm="toDefault"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup.vue'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import ProductionCalendar from '@/components/views/LoadCalendar/ProductionCalendar/ProductionCalendar.vue'
import ProductionCalendarFooter from '@/components/views/LoadCalendar/ProductionCalendarFooter/ProductionCalendarFooter.vue'
import SpinnerSimple from '@/components/ui/loaders/SpinnerSimple/SpinnerSimple.vue'
import VButton from '@/components/simple/button/VButton.vue'
import VSelect from '@/components/Form/VSelect.vue'

import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinIsEdit } from '@/mixins/mixinIsEdit'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProductionCalendarPage',
  components: {
    ButtonGroup,
    ModalConfirmation,
    ProductionCalendar,
    ProductionCalendarFooter,
    SpinnerSimple,
    VButton,
    VSelect
  },
  mixins: [mixinIsLoading, mixinModal, mixinIsEdit],

  data () {
    return {
      selectedYear: null
    }
  },

  computed: {
    ...mapState('calendar', {
      yearList: 'yearList'
    }),
    ...mapGetters('calendar', {
      currentYear: 'currentYear'
    })
  },

  async created () {
    this.initSelectedYear()
  },

  watch: {
    selectedYear: {
      async handler ({ value }) {
        await this.loadCalendar(value)
        this.renderWeekends()
      }
    }
  },

  methods: {
    async loadCalendar (year) {
      try {
        this.startLoading()
        await this.$store.dispatch('calendar/loadCalendarBy', year)
      } finally {
        this.finishLoading()
      }
    },

    async onSave () {
      try {
        this.startLoading()
        await this.$store.dispatch('calendar/updateCalendar')
        this.turnOffIsEdit()
      } finally {
        this.finishLoading()
      }
    },

    onCancel () {
      this.turnOffIsEdit()
      this.$store.commit('calendar/SYNC')
      this.reRenderWeekends()
    },

    async toDefault () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        await this.$store.dispatch('calendar/toDefaultCalendar', this.selectedYear.value)
        this.closeModal()
        this.reRenderWeekends()
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    initSelectedYear () {
      this.selectedYear = this.yearList.find(item => item.value === this.currentYear)
    },

    reRenderWeekends () {
      this.$refs.productionCalendar.reRenderWeekends()
    },
    renderWeekends () {
      this.$refs.productionCalendar.renderWeekends()
    }
  }
}
</script>

<style lang="scss">
.production-calendar-page {
  &__select-loader {
    position: absolute;
    right: -35px;
    bottom: 10px;
  }

  &__select {
    max-width: 230px;

    @media screen and (max-width: $mobile-width) {
      max-width: 100%;
    }
  }
}
</style>
